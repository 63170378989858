import Dashboard from "@/components/Dashboard";
import React from "react";

const Home: React.FunctionComponent = () => {
  return (
    <div className="p-2 border-l w-full">
      <Dashboard />
    </div>
  );
};

export default Home;
